<template>
  <div class="canvas-wrap">
    <div id="map_canvas"></div>
    <div class="introduct-window-area">
      <div class="wrap-top">
        <span>{{ chartsData.name }}</span>
      </div>
      <div class="wrap-content">
        <p>
          {{ chartsData.content }}
        </p>
      </div>
    </div>
    <div class="window-twelve-area">
      <div class="wrap-top">
        <span>{{ chartsData.name }}</span>
      </div>
      <div class="wrap-content">
        <p>
          {{ chartsData.content }}
        </p>
      </div>
    </div>
    <div class="window-wrap">
      <div class="wrap-top">
        <img
          class="logo"
          v-if="chartsData.companyLogoUrl"
          :src="chartsData.companyLogoUrl"
          alt=""
        />
        <span>{{ chartsData.companyName }}</span>
        <!-- <img
          src="../../../assets/icon/el-icon-search.png"
          alt=""
          class="qrcode"
        /> -->
      </div>
      <div class="wrap-content">
        <p>
          {{ chartsData.companyIntroduction }}
        </p>
        <div class="content-swiper">
          <div class="swiper-title">企业荣誉</div>
          <div class="swiper-wrap">
            <swiper class="swiper-content" :options="swiperOption">
              <swiper-slide
                v-for="ele in [chartsData.companyHonorUrl]"
                :key="ele"
              >
                <img v-if="ele" :src="ele" alt="" />
              </swiper-slide>
              <!-- <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div> -->
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getWeather } from '../modules/weather-api'
import { pointIcon } from './map-utils'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import { methods } from './methods'
import { loadBMap } from '../../../utils/baidu-map/loadResources'
export default {
  name: 'baidu-map-component',
  inject: ['app'],
  data() {
    return {
      citys: [
        '赤坎区',
        '霞山区',
        '坡头区',
        '麻章区',
        '廉江市',
        '雷州市',
        '吴川市',
        '遂溪县',
        '徐闻县',
      ],
      citys_overlays: [], // 每个区的label引用
      twelve_area_overlays: [], // 十二片区引用
      areaInfo: [],
      chartsData: 5,
      responeData: null,
      isLoadedMap: false,
      swiperOption: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  components: {
    swiper,
    swiperSlide,
  },
  computed: {},
  methods: {
    ...methods,
    // 初始化图表
    mapHandle() {
      // 主方法 **
      var map = new BMapGL.Map('map_canvas')
      this.app.map = this.map = map
      map.setMapStyleV2({
        styleId: '0b09a3c6ed2d04d268eee6cb8f58ada7',
      })
      map.setMapType(BMAP_EARTH_MAP)
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
      // map.setMapType(BMAP_NORMAL_MAP);      // 设置地图类型为地球模式：BMAP_EARTH_MAP; BMAP_NORMAL_MAP 正常模式

      // map.addEventListener('click', function (e) {
      //   // 监听整张地图的鼠标点击事件
      //   console.log('点击位置经纬度：' + e.latlng.lng + ',' + e.latlng.lat)
      // })

      this.seaArea()

      this.customeControlClass()
      this.isLoadedMap = true

      this.fmtParamer() &&
        this.fmtParamer().callback &&
        this.fmtParamer().callback()
      // this.haveHeightPoint()
    },
    // 根据已有顺序排序
    sortAreaInfo(data) {
      let newArr = []
      this.citys.map((ele, idx) => {
        let dataI = 0
        while (!data[dataI].address.includes(ele) && data.length < dataI) {
          dataI++
        }

        newArr.push(data[dataI])
      })
      console.log('dadda', newArr)
      return newArr
    },
    // 湛渔品牌  数字园区
    loadPointOneLevel(res) {
      if (this.isLoadedMap && this.responeData) {
        // 存入数据
        this.areaInfo = this.responeData.data.map((ele) => ele.url)
        this.citys = this.responeData.data.map((ele) => ele.address)
        let isZhanyuB = this.$route.name === 'zyuBrand'
        let _icon = isZhanyuB
          ? require('../../../assets/img/large-screen/icon-vr.png')
          : require('../../../assets/img/large-screen/icon-video.png')

        isZhanyuB &&
          this.haveHeightPoint(
            this.responeData.data.map((ele) => ele.url.length),
            _icon,
            (data) => {
              console.log('回点了', data)
              if (isZhanyuB) {
                // window.open(this.areaInfo[data])
                this.app.toVr(this.areaInfo[data])
              } else {
                // todo
              }
            }
          )

        if (!isZhanyuB) {
          let requests = pointIcon.map((ele) => {
            return getWeather('now', ele.point)
          })
          Promise.all([...requests]).then(
            (res) => {
              console.log('Promise.all', res)
              // this.nowWeather = res.now.temp
              this.areaInfo = res.map((ele) => ele.now.temp)
              this.twelveAreaMethod(this.areaInfo, false, this.areaInfo)
            },
            (e) => {
              console.error('LocHandle error:' + e)
            }
          )
        }
      }
    },
    // 饲料加工   园区概况
    locateFirstDot(res) {
      if (this.isLoadedMap && this.responeData) {
        // 存入数据
        this.areaInfo = this.responeData.data.map((ele) => ele.companyList)
        this.citys = this.responeData.data.map((ele) => ele.address)

        this.haveHeightPoint(
          this.responeData.data.map((ele) => ele.companyList.length),
          '',
          '',
          this.areaInfo
        )
      }
    },
    // 清除覆盖物
    clearCurrentOverlays() {
      this.citys_overlays.map((ele) => {
        ele.enableMassClear()
      })
      this.map.clearOverlays()

      this.citys_overlays = []

      return true
    },
    // 养殖与苗种地图
    locateBreedingSeeding() {
      if (this.isLoadedMap && this.responeData) {
        // 存入数据
        this.areaInfo = this.responeData.data
        // Todo 完善细节
        // this.twelve_area_overlays.push(position)
        if (this.$route.name === 'breedingSeeding') {
          this.twelveAreaMethod(this.areaInfo, false, this.areaInfo)
        }
      }
    },
    fmtParamer() {
      switch (this.$route.name) {
        case 'zyuBrand':
          return {
            url: 'viewZhanFishBrand/queryMapInfo',
            callback: this.loadPointOneLevel,
          }

        case 'digitalPark':
          return {
            url: 'viewDigitalPark/queryMapInfo',
            callback: this.loadPointOneLevel,
          }

        case 'feedProcessing':
          return {
            url: 'viewFodderProcessing/queryMapInfo',
            callback: this.locateFirstDot,
          }

        case 'breedingSeeding':
          return {
            url: 'viewBreedSeedling/queryMapInfo',
            callback: this.locateBreedingSeeding,
          }

        case 'overview':
          return {
            url: 'parkInformation/queryMapInfo',
            callback: this.locateFirstDot,
          }

        default:
          return {}
      }
    },
    // 加载详情数据
    loadAreaInfo() {
      this.responeData = null
      this.twelve_area_overlays = []
      let handlerObj = this.fmtParamer()
      if (!handlerObj.url) return

      this.$axios(`/zhanjiang-dataview/${handlerObj.url}`)
        .then((res) => {
          console.log('map-zhanjaing ', res.data)
          this.responeData = res
          handlerObj.callback(res)
        })
        .catch(() => {})
    },
  },
  mounted() {
    // 模板编译/挂载之后
    this.loadAreaInfo()
  },
  created() {
    window.initBaiduMapScript = () => {
      this.mapHandle()
    }
    loadBMap('initBaiduMapScript')
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    '$route.name': {
      handler(curVal, oldVal) {
        // this.map && this.clearCurrentOverlays() && this.map.reset()
        // this.loadAreaInfo()
      },
    },
  },
}
</script>
<style lang="less" scoped>
.canvas-wrap {
  width: 100%;
  height: 100%;
  .window-wrap {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    max-width: 4.04rem;
    height: 4.2rem;
    padding: 0.2rem 0.35rem 0.3rem;
    opacity: 0;
    border-radius: 0.07rem;
    background: #dfdae4;
    .wrap-top {
      display: flex;
      height: 0.5rem;
      margin-bottom: 0.12rem;

      align-items: center;
      justify-content: space-between;
    }
    .logo {
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
    }
    span {
      margin: 0 0.08rem;
      color: #111e39;
      font-family: 'Microsoft YaHei';
      font-size: 0.18rem;
      font-weight: 400;

      flex-grow: 1;
    }
    .qrcode {
      width: 0.5rem;
      height: 0.49rem;
    }
    .wrap-content {
      p {
        display: -webkit-box;
        overflow: auto;
        max-height: 1.1rem;
        color: #111e39;
        font-family: 'Microsoft YaHei';
        font-size: 0.14rem;
        font-weight: 400;
        line-height: 0.22rem;

        // 滚动条样式
        &::-webkit-scrollbar {
          // 滚动条整体样式
          // 高宽分别对应横竖滚动条的尺寸
          width: 0.04rem;
          height: 0.04rem;
          // height: 0.01rem;
        }

        &::-webkit-scrollbar-thumb {
          // 滚动条里面小方块
          border-radius: 0.1rem;
          background: #f8f8f8;
          box-shadow: inset 0 0 0.05rem #d8dbe4;
        }

        &::-webkit-scrollbar-track {
          // 滚动条里面轨道
          // -webkit-box-shadow: inset 0 0 0.05rem rgba(38, 43, 67, 1);
          border-radius: 0.1rem;
          background: #ffffff;
        }

        // text-overflow: ellipsis;

        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 5;
      }
      .content-swiper {
        margin-top: 0.28rem;
        margin-right: -0.1rem;
        .swiper-title {
          margin-bottom: 0.14rem;
          color: #111e39;
          font-family: 'Microsoft YaHei';
          font-size: 0.18rem;
          font-weight: 400;
        }
        .swiper-wrap {
          position: relative;
          width: calc(1.04rem * 3 + 0.5rem);
          height: calc(0.59rem + 0.59rem + 0.2rem);
          margin-right: -0.15rem;
          margin-left: -0.18rem;

          .swiper-content {
            position: initial;
            width: calc(1.04rem * 3 + 0.2rem);
            height: calc(0.59rem + 0.59rem + 0.2rem);
            margin-right: auto;
            margin-left: auto;

            .swiper-slide {
              width: 1.04rem;
              height: 0.59rem;
            }
            .swiper-button-prev {
              left: -0.06rem;
              display: none;
              width: 0.1rem;
              height: 0.18rem;
              margin-top: -0.09rem;
              background: url(../../../assets/img/large-screen/icon-swiper-left.png);
            }
            .swiper-button-next {
              right: -0.06rem;
              display: none;
              width: 0.1rem;
              height: 0.18rem;
              margin-top: -0.09rem;
              background: url(../../../assets/img/large-screen/icon-swiper-right.png);
            }
          }
          img {
            display: block;
            width: 1.04rem;
            width: 100%;
            height: 0.59rem;
            height: 100%;
          }
        }
      }
    }
  }
  .window-twelve-area {
    .window-wrap;
    & {
      width: 2.92rem;
      max-width: 2.92rem;
      height: 1.2rem;
      padding: 0.12rem 0.16rem;
    }
    .wrap-top {
      margin-bottom: 0;
      span {
        margin: 0;
      }
    }
    .wrap-content {
      p {
        height: 0.44rem;
        max-height: initial;

        -webkit-line-clamp: 2;
      }
    }
  }
  .introduct-window-area {
    .window-twelve-area;
    & {
      height: 3.2rem;
    }
    .wrap-content {
      p {
        overflow: auto;
        height: 2.6rem;
      }
    }
  }
}
/deep/ .area-point-num {
  text-align: center;
}

// 百度地图实例节点
/deep/ #platform {
  .BMap_bubble_pop {
    // 默认宽度： 5.27rem高度 title + content = 1.88rem 另箭头height： 0.31rem； width 0.58rem；
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    background: none !important;
    & > img {
      display: none;
    }
    .BMap_bubble_title,
    .BMap_bubble_top {
      visibility: hidden !important;
    }
    .BMap_bubble_buttons {
      // 关闭按钮
      visibility: hidden !important;
    }
    .BMap_bubble_center {
      // 主要容器位置
      background: none;
      .BMap_bubble_content {
        overflow: hidden !important;
        box-sizing: border-box;
        border-radius: 0.12rem;
        background: #dfdae4 !important;
      }
    }
  }
  .shadow img {
    display: none !important;
  }
}

</style>